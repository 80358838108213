import "./categorypage.scss";
import "./productpage.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";

import $ from "jquery";

// increase/ decrease input amount productpage
dompack.register("#decrease", amountnode => amountnode.addEventListener("click", evt =>
{
  dompack.stop(evt);
  decreaseValue();
}));

dompack.register("#increase", amountnode => amountnode.addEventListener("click", evt =>
{
  dompack.stop(evt);
  increaseValue();
}));

function increaseValue()
{
  let amountnode = dompack.qS('#amount');
  let value = parseInt(dompack.qS('#amount').value, 10);

  value = isNaN(value) ? 0 : value;
  value++;

  dompack.qS('#amount').value = value;
  domevents.fireHTMLEvent(amountnode, 'input');
}

function decreaseValue()
{
  let amountnode = dompack.qS('#amount');
  let value = parseInt(dompack.qS('#amount').value, 10);

  value = isNaN(value) ? 0 : value;
  value < 1 ? value = 1 : '';
  value--;

  dompack.qS('#amount').value = value;
  domevents.fireHTMLEvent(amountnode, 'input');
}

$( document ).ready(function()
{

    //active state tab productpage
    $( '.product__info ul.nav a' ).on( 'click', function ()
    {
      $( '.product__info ul.nav' ).find( 'li.active' ).removeClass( 'active' );
      $( this ).parent( 'li' ).addClass( 'active' );
    });

    // init magnific popup in productpage
    var form = document.getElementById("product__images__preview__container");
    var have_images = document.querySelectorAll('.product__images__grid, .product__images__preview').length !== 0;

    if (typeof (form) != 'undefined' && form !== null && have_images)
    {
        $('.product__images__grid, .product__images__preview').magnificPopup(
        {
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery:
            {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image:
            {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: item => item.el.find('img').attr('alt')
            }
        });
    }

    // init slick-carousel on product images
    $(".product__images__grid").slick({
     dots: true,
      infinite: false,
      speed: 300,
      centerMode: false,
      variableWidth: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });

    // stickytabs productpage
    $('#product__tabs .nav-tabs').stickyTabs();

});

function mobileProductOptionsPlaceholder()
{
  //on mobile, move product options block to placeholder
  if(!window.matchMedia("(max-width:992px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobile_product__options_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.product__options');
  if(moveto.length == 0)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileProductOptionsPlaceholder);
